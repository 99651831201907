type ActionAuthorsProps = {
  currentAuthorIndex: number;
  totalAuthors: number;
  authorName: string;
};

const ActionAuthors = ({
  currentAuthorIndex,
  totalAuthors,
  authorName,
}: ActionAuthorsProps) => {
  if (
    (currentAuthorIndex === 0 && totalAuthors === 1) ||
    (currentAuthorIndex === 1 && totalAuthors >= 2)
  ) {
    return (
      <>
        <strong>{authorName}</strong>&nbsp;
      </>
    );
  } else if (currentAuthorIndex === 0 && totalAuthors > 1) {
    return (
      <>
        <strong>{authorName}</strong> &amp;&nbsp;
      </>
    );
  } else if (currentAuthorIndex === 2 && totalAuthors === 3) {
    return <>+ 1 other&nbsp;</>;
  } else if (currentAuthorIndex === 2 && totalAuthors > 3) {
    return <>+ {totalAuthors - currentAuthorIndex} others&nbsp;</>;
  } else {
    return null;
  }
};

export default ActionAuthors;
