import { useState } from "react";

import Image from "next/image";

import { IconArrowLeft, IconArrowRight, IconX } from "@tabler/icons";

import { ActionItemType } from "types/action";

import { getBlurDataURL } from "helpers/utils/images";
import { Colors } from "helpers/utils/uiSetup";

type ActionItemProps = {
  item: ActionItemType;
};

const ActionItemImages = ({ item }: ActionItemProps) => {
  const carouselDotCount = item.images.length - 1;
  let carouselDots = [];
  for (let i = 0; i < carouselDotCount; i++) {
    carouselDots.push(<div className="rounded-full w-2 h-2 bg-white/70" />);
  }

  const [photoModalActive, setPhotoModalActive] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const isFirstImageSelected = activeImage === 0;
  const isLastImageSelected = activeImage === item.images.length - 1;

  const handleClose = () => {
    setPhotoModalActive(false);
    setActiveImage(0);
  };

  const handleClickPrevious = () => {
    setActiveImage((activeImage) => Math.max(activeImage - 1, 0));
  };

  const handleClickNext = () => {
    setActiveImage((activeImage) =>
      Math.min(activeImage + 1, item.images?.length)
    );
  };

  return (
    <>
      <div
        onClick={() => handleClose()}
        className={`z-50 ${
          photoModalActive ? "flex" : "hidden"
        } justify-center items-center fixed w-screen h-screen top-0 left-0 right-0 bottom-0 bg-navy bg-opacity-80 backdrop-blur-lg px-5 py-20 sm:p-20 xl:py-32 xl:px-40 pointer-events-auto select-none fade-in-point5s`}
      >
        <button className="absolute top-5 sm:top-20 right-5 sm:right-20 outline-none">
          <IconX color={Colors.metalGrey} />
        </button>
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex flex-col w-full h-full"
        >
          <div className="relative h-full w-full">
            {item.images[activeImage] && (
              <Image
                src={item.images[activeImage].url}
                key={item.images[activeImage].url}
                className="fade-in-point5s pointer-events-none"
                layout="fill"
                objectFit="contain"
                alt="An image representing the impact action stated below."
                sizes="(min-width:1280px) 100vw, 1280px"
              />
            )}
          </div>
          <div className="flex flex-row text-white mt-5 lg:mt-10 items-center">
            <div className="flex w-1/3 justify-start">
              {!isFirstImageSelected && (
                <div
                  onClick={handleClickPrevious}
                  className="flex gap-x-1 p-1 cursor-pointer"
                >
                  <IconArrowLeft color={Colors.cleanTech} />
                  Previous
                </div>
              )}
            </div>
            <div className="flex w-1/3 justify-center">
              {activeImage + 1} / {item.images.length}
            </div>
            <div className="flex w-1/3 justify-end">
              {!isLastImageSelected && (
                <div
                  onClick={handleClickNext}
                  className="flex gap-x-1 p-1 cursor-pointer"
                >
                  Next
                  <IconArrowRight color={Colors.cleanTech} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col gap-2.5">
        {item.images[0] && (
          <Image
            onClick={
              carouselDotCount > 0 ? () => setPhotoModalActive(true) : null
            }
            src={item.images[0].url}
            key={item.images[0].url}
            className={`rounded-xl ${
              carouselDotCount > 0 ? "cursor-pointer" : ""
            }`}
            layout="responsive"
            height={item.images[0].height}
            width={item.images[0].width}
            alt="An image representing the impact action stated below."
            sizes="(min-width:1024px) 325px, (min-width:640px) 50vw, 100vw"
          />
        )}
        {item.image && (
          <Image
            src={`https:${item.image.url}`}
            className="rounded-xl"
            layout="responsive"
            height={item.image.height}
            width={item.image.width}
            placeholder="blur"
            blurDataURL={getBlurDataURL(`https:${item.image.url}`, 40)}
            alt="An image representing the impact action stated below."
            sizes="(min-width:1024px) 325px, (min-width:640px) 50vw, 100vw"
          />
        )}
        <div className="absolute bottom-5 left-1/2 -translate-x-1/2 flex flex-row gap-3 h-2">
          {carouselDotCount > 0 && (
            <>
              <div className="rounded-full w-2 h-2 bg-white" />
              {carouselDots}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ActionItemImages;
