import Head from "next/head";

import { trimForwardSlashPrefix } from "helpers/utils/formatting";

import { BASE_URL, DEFAULT_PAGE_THUMBNAIL } from "constants/general";

/**
 * Removes redundancy of setting meta tags across pages.
 * @param {string} title - Page Title.
 * @param {string} description - Page description.
 * @param {string} absoluteThumbnailURL - Page thumbnail URL, absolute URL.
 * @param {string} relativeThumbnailURL - Page thumbnail URL, relative to the current site.
 * @param {string} children - Content of the component.
 * @returns {ReactComponent~} _ Return react component.
 * (draft)
 */

type HeadWithTagsProps = {
  title: string;
  description?: string;
  absoluteThumbnailURL?: string;
  relativeThumbnailURL?: string;
  noTitlePrefix?: boolean;
};
const HeadWithTags = ({
  title,
  description,
  absoluteThumbnailURL = null,
  relativeThumbnailURL = null,
  noTitlePrefix = false,
}: HeadWithTagsProps) => {
  title = noTitlePrefix ? title : title + " | Pinwheel";
  const thumbnailURL = absoluteThumbnailURL
    ? absoluteThumbnailURL
    : relativeThumbnailURL
    ? BASE_URL + "/" + trimForwardSlashPrefix(relativeThumbnailURL)
    : DEFAULT_PAGE_THUMBNAIL;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={thumbnailURL} />
      <link rel="icon" href="/img/favicon/favicon.ico" />
    </Head>
  );
};

export default HeadWithTags;
