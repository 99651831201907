import Image from "next/image";

import { Theme } from "types/ui";

import { useUserContext } from "contexts/UserContext";

import { getBlurDataURL } from "helpers/utils/images";

import LinkWithLoading from "components/buttons/LinkWithLoading";

import CleanTechAvatar from "public/img/avatars/flat/clean_tech.svg";
import NewHorizonsAvatar from "public/img/avatars/flat/new_horizons.svg";
import OceanRescueAvatar from "public/img/avatars/flat/ocean_rescue.svg";
import WildernessAvatar from "public/img/avatars/flat/wilderness.svg";

const getAvatarByTheme = (theme: Theme) => {
  let src = WildernessAvatar;
  if (theme === Theme.wilderness) {
    src = WildernessAvatar;
  } else if (theme === Theme.oceanRescue) {
    src = OceanRescueAvatar;
  } else if (theme === Theme.cleanTech) {
    src = CleanTechAvatar;
  } else if (theme === Theme.newHorizons) {
    src = NewHorizonsAvatar;
  }

  return src;
};

type AvatarImageProps = {
  imageURL?: string;
  imageName: string;
  href: string;
  className?: string;
};

const AvatarImage = ({
  imageName,
  href,
  className = "border border-white h-10",
  imageURL = "",
}: AvatarImageProps) => {
  let formattedURL = imageURL
    ? imageURL.startsWith("//")
      ? `https:${imageURL}`
      : imageURL
      ? imageURL
      : ""
    : "";
  const { user } = useUserContext();

  return (
    <LinkWithLoading
      className={`relative aspect-square rounded-full overflow-hidden ${className}`}
      href={href}
    >
      {formattedURL ? (
        <Image
          src={formattedURL}
          objectFit="cover"
          layout="fill"
          placeholder="blur"
          blurDataURL={getBlurDataURL(formattedURL, 20)}
          alt={`${imageName}'s Profile Picture`}
          sizes="56px"
          quality={100}
        />
      ) : (
        <Image
          src={getAvatarByTheme(user.theme || Theme.wilderness)}
          objectFit="cover"
          layout="fill"
          alt={`${imageName}'s Profile Picture`}
        />
      )}
    </LinkWithLoading>
  );
};

export default AvatarImage;
