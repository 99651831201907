/*
   This component plays a 16:9 Vimeo video by default
   and fills the container it is placed in.

   If the height and width props are provided then the aspect ratio
   will be adjusted to the desired ratio. These can usually be obtained
   programmatically using the oEmbed API.

   The url prop should be taken from the "Share Link" in the Vimeo UI
   Thumbnails and player colors are controlled in the Vimeo UI
 */
import { ReactElement, useEffect, useRef, useState } from "react";
import { BaseReactPlayerProps } from "react-player/base";
import LazyPlayer from "react-player/lazy";
import VimeoPlayer, { VimeoPlayerProps } from "react-player/vimeo";

import { useUIContext } from "contexts/UIContext";

import { VolumeOff, VolumeOn } from "components/images/VideoControls";

const Player = ({
  lazy,
  ...props
}: BaseReactPlayerProps | VimeoPlayerProps): ReactElement<
  LazyPlayer | VimeoPlayer
> => {
  if (lazy) {
    return <LazyPlayer {...props} />;
  }

  return <VimeoPlayer {...props} />;
};

type VimeoVideoProps = {
  url: string;
  height?: number;
  width?: number;
  autoPlay: boolean;
  className?: string;
  lazy?: boolean;
};

export const VimeoVideo = ({
  url,
  width = 16,
  height = 9,
  autoPlay,
  className = "",
  lazy = false,
}: VimeoVideoProps) => {
  // Context
  const { autoPlayingVideo, setAutoPlayingVideo } = useUIContext();

  // Error handling
  const [muted, setMuted] = useState(autoPlay);

  // Calculate correct aspect ratio
  const getVideoAspectRatio = (width, height) => {
    const ratio = width / height;
    return ratio.toString();
  };

  // Don't render on server side as this component requires the window object
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== undefined) {
      setHasWindow(true);
    }
  }, []);

  const videoContainerRef = useRef(null);

  useEffect(() => {
    const observerCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setAutoPlayingVideo(url);
      }
    };
    const observerOptions = {
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    };
    if (hasWindow) {
      const observer = new IntersectionObserver(
        observerCallback,
        observerOptions
      );
      if (videoContainerRef.current) {
        observer.observe(videoContainerRef.current);
      }
    }
  }, [hasWindow, setAutoPlayingVideo, url]);

  if (!hasWindow) return null;

  return (
    <div
      className={`relative sm:rounded-2xl overflow-hidden ${className}`}
      ref={videoContainerRef}
      style={{
        aspectRatio: getVideoAspectRatio(width, height),
      }}
    >
      {autoPlay && (
        <div
          className="absolute top-3 right-3 cursor-pointer z-20 hidden xl:block"
          onClick={() => setMuted(!muted)}
        >
          {muted ? <VolumeOff /> : <VolumeOn />}
        </div>
      )}
      <Player
        lazy={lazy}
        className="absolute top-0 left-0"
        url={url}
        width="100%"
        height="100%"
        playsinline={true}
        playing={autoPlay && url === autoPlayingVideo}
        loop={autoPlay && url === autoPlayingVideo}
        volume={muted ? 0 : 1}
        muted={muted}
        config={{
          playerOptions: {
            controls: true,
          },
        }}
      />
    </div>
  );
};
