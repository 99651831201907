import * as React from "react";
import { LegacyRef, forwardRef } from "react";

import { IconCircleX } from "@tabler/icons";

import { ActionItemType } from "types/action";

import AuthorName from "components/actionFeed/ActionAuthors";
import ActionItemImages from "components/actionFeed/ActionItemImages";
import AvatarImage from "components/avatars/AvatarImage";
import { VimeoVideo } from "components/containers/VimeoVideo";
import { getImpactIcon } from "components/images/ImpactIcons";

type ActionItemProps = {
  item: ActionItemType;
  handleDelete?: () => void;
};

const ActionItem = forwardRef(
  (
    { item, handleDelete }: ActionItemProps,
    ref?: LegacyRef<HTMLDivElement>
  ) => {
    /*
     * TODO when this component is extended.
     *  https://github.com/pinwheel-cc/frontend/pull/525/files#r979797498
     * */
    const hasAuthor = item.authors?.length > 0;
    const hasProject = item.project !== null;
    const totalAuthors = item.authors?.length;

    return (
      <div
        className="bg-off-white/90 rounded-2xl mb-5 p-2.5 relative shadow-30px-beige-35"
        ref={ref}
      >
        {item.is_my_action && handleDelete && (
          <>
            <button
              className={
                "absolute top-4 right-4 flex items-center justify-center h-11 w-11 outline-none " +
                "text-white rounded-full mix-blend-difference z-10 peer"
              }
              onClick={handleDelete}
            >
              <IconCircleX stroke={1.5} />
            </button>
            <div
              className={
                "hidden absolute right-14 top-5 py-2 px-3 " +
                "rounded-lg border border-white bg-[#4D4D4D] " +
                "text-white text-body14 font-light whitespace-nowrap peer-hover:block"
              }
            >
              Delete
            </div>
          </>
        )}
        <ActionItemImages item={item} />
        {item.video && (
          <VimeoVideo
            url={item.video.url}
            width={item.video.width}
            height={item.video.height}
            autoPlay={true}
            className="rounded-xl"
          />
        )}
        <div className="flex flex-col mt-3 gap-3 px-1.5 pb-1.5">
          {(hasAuthor || hasProject) && (
            <div className="flex-1 flex flex-row items-center justify-start gap-x-2.5">
              {hasAuthor &&
                item.authors.slice(0, 2).map(({ first_name, image_url }) => {
                  return (
                    <AvatarImage
                      key={image_url}
                      imageURL={image_url}
                      imageName={first_name}
                      href=""
                    />
                  );
                })}
              {hasProject && (
                <AvatarImage
                  imageURL={item.project.main_image}
                  imageName={item.project.name}
                  href={`/projects/${item.project.slug}`}
                />
              )}
            </div>
          )}
          {(hasAuthor || hasProject) && (
            <p className="flex-1 text-navy text-left text-body14 leading-tight">
              {hasAuthor &&
                item.authors.map(({ first_name, image_url }, index) => {
                  return (
                    <AuthorName
                      key={image_url}
                      currentAuthorIndex={index}
                      totalAuthors={totalAuthors}
                      authorName={first_name}
                    />
                  );
                })}
              {item.is_system_action && (
                <span className="font-bold">Pinwheel </span>
              )}
              {hasProject && (
                <>
                  with <strong>{item.project.name}</strong>
                </>
              )}
            </p>
          )}
          <p className="flex-1 text-navy text-left text-body18 font-normal leading-normal py-3">
            {item.description}
          </p>
          <div className="flex-1 flex flex-row justify-between">
            <div className="bg-navy h-9 w-9 flex items-center justify-center rounded-xl">
              <div className="h-5 w-5 flex items-center justify-center">
                {getImpactIcon(item.theme)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ActionItem.displayName = "ActionItem";

export default ActionItem;
